import { css } from '@emotion/react'
import { ComponentPropsWithoutRef } from 'react'

import { bezier } from '@/theme/mixins'

interface Props extends ComponentPropsWithoutRef<'svg'> {
  iconType: 'ARROW' | 'EXTERNAL' | 'LIGHTBOX' | 'DOWNLOAD'
}

export const DatoLinkIcon = ({
  iconType,
  ...props
}: Props): JSX.Element => {
  const styles = {
    container: css`
      display: inline-block;
      width: 0;
      overflow: visible;
    `,
    icon: css`
      display: flex;
      height: 1em;
      width: auto;
      --stroke-width: calc(var(--font-weight) / 300 * 1px);
      path {
        fill: transparent;
        stroke: currentColor;
        stroke-width: var(--stroke-width);
        stroke-linecap: round;
      }
    `,
    arrow: css`
      margin: 0 0 -0.15em 0.333em;
      transition: transform 300ms ${bezier.easeOut};
      @media (hover: hover) {
        a:hover > div > & {
          transform: translate3d(0.15em, 0, 0);
        }
      }
    `,
    external: css`
      margin: 0 0 -0.09em 0.333em;
      path {
        stroke-width: calc(var(--font-weight) / 325);
      }
      g {
        transition: transform 300ms ${bezier.easeOut};
        transform-origin: 50%;
      }
      @media (hover: hover) {
        a:hover > div > & g {
          transform: translate3d(0.08em, -0.08em, 0);
        }
      }
    `,
    download: css`
      margin: 0 0 -0.09em 0.333em;
      path {
        stroke-width: calc(var(--font-weight) / 325);
      }
      g {
        transition: transform 300ms ${bezier.easeOut};
        transform-origin: 50%;
      }
      @media (hover: hover) {
        a:hover > div > & g {
          transform: translate3d(
            0,
            calc(0.18em - var(--stroke-width) * 1px),
            0
          );
        }
      }
    `,
    // lightbox: css`
    //   margin: 0 0 -0.09em 0.333em;
    //   path {
    //     transition: transform 300ms ${bezier.easeOut};
    //   }
    //   @media (hover: hover) {
    //     a:hover > & path {
    //       &:nth-of-type(1) {
    //         transform: translate3d(0.1em, -0.1em, 0);
    //       }
    //       &:nth-of-type(2) {
    //         transform: translate3d(-0.1em, 0.1em, 0);
    //       }
    //     }
    //   }
    // `,
  }
  switch (iconType) {
    case 'ARROW':
    case 'LIGHTBOX':
    case 'EXTERNAL':
    case 'DOWNLOAD':
      return (
        <div css={styles.container}>
          <svg
            viewBox="0 0 7 16"
            css={[styles.icon, styles.arrow]}
            {...props}
          >
            <path d="M1 3L6 8L1 13" />
          </svg>
        </div>
      )
    // case 'EXTERNAL':
    //   return (
    //     <svg
    //       viewBox="0 0 16 16"
    //       css={[styles.icon, styles.external]}
    //       {...props}
    //     >
    //       <path d="M10.5 10.5V13.5H2.5V5.5H5.5" />
    //       <g>
    //         <path d="M9.5 3L13 3L13 6.5" />
    //         <path d="M12 4L8 8" />
    //       </g>
    //     </svg>
    //   )
    // case 'DOWNLOAD':
    //   return (
    //     <svg
    //       viewBox="0 0 13 16"
    //       css={[styles.icon, styles.download]}
    //       {...props}
    //     >
    //       <path d="M10.5 11.5V13.5H2.5V11.5" />
    //       <g>
    //         <path d="M9 7L6.5 9.5L4 7" />
    //         <path d="M6.47485 8.5V1.96447" />
    //       </g>
    //     </svg>
    //   )
  }
}
